export const PTE = {
  EBC_DOMAIN: "https://pte1-agency.thehartford.com",
  INTERNAL_DOMAIN: "https://ebcintranet-qa.thehartford.com",
  INTERNAL_AUTH: {
    CLIENT_ID: "68a471cd-6ffb-4129-98ed-08610a600f59",
    AUTHORITY:
      "https://login.microsoftonline.com/a311fc62-83f4-45f0-9502-1bb2247d4c8d/",
  },
  EXTERNAL_AUTH: {
    CLIENT_ID: "34745e42329c5282c8ce209cce566cea",
    AUTHORITY:
      "https://qa-apis-hig.thehartford.com/cafe-oauth-ciam-session/oauth2",
    KNOWAUTHORITIES: ["https://qa-apis-hig.thehartford.com"],
    REDIRECT_URI: "https://pte-appetite.thehartford.com",
    SCOPES: ["cafe.app.external.user"],
  },
  BASIC_API_URL: "https://qa-APIs-hig.thehartford.com/cafe/v1",
  IBM_CLIENT_ID: "34745e42329c5282c8ce209cce566cea",
  TEALIUM_ENVIRONMENT: "qa",
};
