import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { HukButton, HukSelect, HukIcon } from "huk-react-bindings";

import { GLOBAL } from "env/global";
import { getAppEnv } from "env/env";
import { businessTypes } from "shared/globalData";
import {
  trackCustomEvent,
  trackCustomEventError,
} from "tealium/trackCustomEvent";
import { requiredFieldErrorMessage, pageTag } from "shared/messages";

import "./AppetiteGuideSearch.scss";

const AppetiteGuideSearch = ({ activeTabID, handleActiveTab }) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const userType = sessionStorage.getItem("userType");
  const [businessType, setBusinessType] = useState("");
  const [businessTypeFullName, setBusinessTypeFullName] = useState("");
  const { riskState } = useSelector((state) => state.riskStateReducer);

  // refresh dynamic input field when tab is changed.  This helps remove any existing validation errors when coming back to tab
  /* istanbul ignore next */
  useEffect(() => {
    setRefreshCount((refreshCount % 100) + 1); // used to reset validation by using HUK refresh attribute
  }, [activeTabID]);

  const checkAllFieldsValid = () => {
    let allFieldsValid = true;

    if (!riskState) {
      trackCustomEventError(
        "Risk State",
        `${requiredFieldErrorMessage}`,
        "true"
      );
      allFieldsValid = false;
    }

    if (!businessType) {
      trackCustomEventError(
        "Appetite Guide BusinessType Drop down",
        `${requiredFieldErrorMessage}`,
        "true"
      );
      allFieldsValid = false;
    }

    return allFieldsValid;
  };

  const handleSubmit = (e) => {
    /* istanbul ignore next */
    e.preventDefault();

    if (!checkAllFieldsValid()) return;
    const env =
      userType === "external"
        ? getAppEnv().EBC_DOMAIN
        : getAppEnv().INTERNAL_DOMAIN;

    window.open(
      `${env}/classDataSearch/AppetiteGuide/PDF/CDRAppGuide_${riskState}_${businessType}.pdf`,
      "_blank"
    );

    trackCustomEvent(
      `${pageTag}`,
      "Appetite Guide Button Click",
      `Appetite Guide Search`,
      `RiskState_${riskState} | Business Type_${businessTypeFullName}`,
      "true"
    );
  };

  const handleSelectBlur = () => {
    const business = businessTypes.find((b) => b.value === businessType);
    // this is to take into account if someone blurs without making a selection business.name will be undefined
    if (business && business.name !== undefined) {
      setBusinessTypeFullName(business.name);
    }

    if (!businessType) {
      trackCustomEventError(
        "Appetite Guide BusinessType Drop down",
        `${requiredFieldErrorMessage}`,
        "true"
      );
    } else {
      trackCustomEvent(
        `${pageTag}`,
        "Select Click - (select-one)",
        `Appetite Guide BusinessType Drop down`,
        `${business.name}`, // use local variable as state variable is holding onto prior value due to asynchronous nature of useState
        "true"
      );
    }
  };

  return (
    <div className="industry_guide_container">
      <form onSubmit={handleSubmit} data-testid="formID">
        <div data-testid="AppetiteGuideSearch">
          <div className="row">
            <div className="col-md-7 col-lg-6 col-xl-5">
              <HukSelect
                nopadding
                labelformat="text"
                gbxunmask={true}
                value={businessType}
                label="Business Type"
                refresh={refreshCount}
                helpertextformat="text"
                options={businessTypes}
                optionsmaxheight="13rem"
                dropposition="bottom-left"
                placeholder="Please Select"
                data-testid="businessDropDownID"
                datamissing={requiredFieldErrorMessage}
                required={activeTabID === "appetiteTabID"}
                onInput={(e) => setBusinessType(e.target.value)}
                onblur={(e) => {
                  handleSelectBlur();
                }}
              />
            </div>
            <div className="col-md col-lg-4 col-xl-3 mt-4">
              <HukButton
                type="submit"
                text="Search"
                data-testid="HukSearchBtn"
                hukclass="btn-primary btn-md"
                data-dl={`{"da_track": "true", "event_id": "Appetite Guide Search Btn"}`}
              />
            </div>
          </div>
        </div>
      </form>

      <div className="row mt-4">
        <div className="d-flex col-lg-10 col-xl-8 py-3 pdf_message">
          <HukIcon className="me-3" icon="icon icon-more-information" />
          <p className="m-0 col-md">
            <span className="state">
              Some classes may not be offered to every agent in every state
            </span>
            <br /> If you’re looking for our general appetite on a countrywide
            basis, choose Connecticut as the risk state. Variations may apply
            based on specific state/exposure information. If you need detailed
            class info, search &nbsp;
            {/* use button to avoid accessibility issue instead of a tag with empty href*/}
            <button
              className="spectrum_tab_link"
              onClick={() => handleActiveTab("spectrumTabID")}
            >
              Spectrum Class.
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppetiteGuideSearch;
