export const PROD = {
  EBC_DOMAIN: "https://agency.thehartford.com",
  INTERNAL_DOMAIN: "https://ebcintranet.thehartford.com",
  ICON_DOMAIN:
    "https://esubmissions.thehartford.com/custmgmt/am_opener.jsp?src=sg",
  INTERNAL_AUTH: {
    CLIENT_ID: "bddf4f26-ec7f-403b-9fac-2c5e7ebb9b82",
    AUTHORITY:
      "https://login.microsoftonline.com/a311fc62-83f4-45f0-9502-1bb2247d4c8d/",
  },
  EXTERNAL_AUTH: {
    CLIENT_ID: "9928f53b59cfcf15a16c3fe98e4f31e0",
    AUTHORITY:
      "https://apis-hig.thehartford.com/cafe-oauth-ciam-session/oauth2",
    KNOWAUTHORITIES: ["https://apis-hig.thehartford.com"],
    REDIRECT_URI: "https://appetite.thehartford.com",
    SCOPES: ["cafe.app.external.user"],
  },
  BASIC_API_URL: "https://APIs-hig.thehartford.com/cafe/v1",
  IBM_CLIENT_ID: "9928f53b59cfcf15a16c3fe98e4f31e0",
  TEALIUM_ENVIRONMENT: "prod",
};
